import './components'

document.addEventListener('DOMContentLoaded', function () {
  revealOnScroll()
})

const revealOnScroll = () => {
  const targets = document.querySelectorAll('.reveal-on-scroll')

  if (targets) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fadeIn')
        }
      })
    })

    targets.forEach(function (target) {
      if (!isInitiallyVisible(target)) {
        target.classList.add('opacity-0')
        observer.observe(target)
      }
    })

    function isInitiallyVisible(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom >= 0 &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth) &&
        rect.right >= 0
      )
    }
  }
}
