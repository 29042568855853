<template>
  <div>
    <slot name="label">
      <label class="mb-1 block text-xs font-medium leading-6 text-gray-700" :for="name" v-show="label">
        {{ label }} <span class="required" v-if="required">*</span>
      </label>
    </slot>

    <div class="relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 flex items-center pl-4" v-if="leadingAddon">
        <span class="text-sm text-gray-500">{{ leadingAddon }}</span>
      </div>

      <input
        :id="name"
        :name="name"
        :type="type"
        :inputmode="inputmode"
        class="block h-12 w-full rounded-2xl border-0 py-3 ring-1 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-500 sm:text-sm"
        :class="[
          showError ? 'bg-red-50 text-red-500 ring-2 ring-red-300' : 'text-gray-900 ring-gray-200',
          leadingAddon ? 'pl-8' : 'pl-4',
          trailingAddon || !!$slots['trailing-addon'] ? 'pr-10' : 'pr-4',
        ]"
        :placeholder="placeholder"
        :ariaDescribedby="ariaDescribedby"
        :value="value || modelValue"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        @blur="$emit('blur', modelValue)"
        @input="inputChanged"
        v-if="type == undefined || type !== 'textarea'"
      />

      <textarea
        :id="name"
        :name="name"
        :rows="rows"
        class="block w-full rounded-2xl border-0 py-3 ring-1 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-500 sm:text-sm"
        :class="[
          showError ? 'bg-red-50 text-red-500 ring-2 ring-red-300' : 'text-gray-900 ring-gray-200',
          leadingAddon ? 'pl-8' : 'pl-4',
          trailingAddon || !!$slots['trailing-addon'] ? 'pr-10' : 'pr-4',
        ]"
        :placeholder="placeholder"
        :ariaDescribedby="ariaDescribedby"
        :value="value || modelValue"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        @blur="$emit('blur', modelValue)"
        @input="inputChanged"
        v-else
      ></textarea>

      <div class="absolute inset-y-0 right-0 flex items-center pr-4" v-if="trailingAddon || !!$slots['trailing-addon']">
        <div class="text-xs text-gray-500" :id="ariaDescribedby">
          <slot name="trailing-addon">
            {{ trailingAddon }}
          </slot>
        </div>
      </div>
    </div>

    <div class="mt-1.5 text-xs text-red-500" v-show="showError" v-html="error"></div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    inputmode: String,
    rows: {
      type: String,
      default: '6',
    },
    error: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: String,
    leadingAddon: {
      type: String,
      default: '',
    },
    trailingAddon: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showError: Boolean(this.error),
    }
  },

  watch: {
    error: function (value) {
      this.showError = value !== ''
    },
  },

  methods: {
    inputChanged: function (event) {
      this.showError = event.target.value === ''
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>
