import { createApp } from 'vue'
import Textbox from './components/Textbox.vue'
import vSelect from './components/Select.vue'

const el = document.getElementById('app')
const app = createApp({
  components: {
    Textbox: Textbox,
    vSelect: vSelect,
  },
})
app.mount(el)
